import { Helmet } from "react-helmet-async";

interface IMetaProps {
  title?: string;
  canonical?: string;
  description?: string;
  noindex?: boolean;
}

const MetaHelmet =({
  title = "Feedback Hub",
  canonical,
  description= "Feedback Hub - The bridge between corporations and customers.",
  noindex = false
}: IMetaProps)=> {

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {canonical && <link rel="canonical" href={canonical}/>}
      {noindex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}

export default MetaHelmet;