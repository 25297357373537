import Cookies from 'js-cookie'
import { logoutUser, setUser } from '../../../userSlice';
import { fableboxApi } from '../api';
import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

const setAuthinticatedUserCookies =(data:ILoginRepsonse)=> { 
  Cookies.set(
    'accessToken',
    data.userToken,
    {expires: new Date(data.tokenExpiration)}
  )
  Cookies.set(
    'tokenExpiration',
    data.tokenExpiration,
    {expires: new Date(data.tokenExpiration)}
  )
}

const SSOMutation =(
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, "fableboxApi">,
  url:string
) => builder.mutation<ILoginRepsonse, string>({
  query: (token)=> ({
    url: url,
    method: 'POST',
    body: {
      accessToken: token,
      referredBy: window.localStorage.getItem("referredBy")
    },
  }),
  async onQueryStarted(_args, { dispatch, queryFulfilled }) {
    try {
      const { data } = await queryFulfilled;
      if ("error" in data) return;
      dispatch(setUser(data.user));
      setAuthinticatedUserCookies(data);
    } catch (error) {
      console.log(error)
    }
  },
})

export const authApi = fableboxApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<GenericResponse, RegisterRequest>({
      query: (data) => ({
        url: 'users/register',
        method: 'POST',
        body: {
          ...data,
          referredBy: window.localStorage.getItem("referredBy")
        },
      })
    }),
    login: builder.mutation<ILoginRepsonse, LoginRequest>({
      query: (data) => ({
        url: 'users/login',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ()=> ["Characters","Books"],
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if ("error" in data) return;
          dispatch(setUser(data.user));
          setAuthinticatedUserCookies(data)
        } catch (error) {
          console.log(error)
        }
      },
    }),
    googleSSO: SSOMutation(builder, 'users/googleSSO'),
    facebookSSO: SSOMutation(builder, 'users/facebookSSO'),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'users/logout',
        method: 'POST',
        credentials: 'include',
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logoutUser());
          Cookies.remove('accessToken');
          Cookies.remove('tokenExpiration');
        } catch (error) {
          console.log(error)
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGoogleSSOMutation,
  useFacebookSSOMutation
} = authApi;