import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

const getErrorFieldsObject = (errorFields: IErrorField[]) => {
  const errorFieldsObject: {[key: string]: string} = {};

  errorFields.forEach((item: IErrorField) => {
    errorFieldsObject[item.field] = item.message;
  });

  return errorFieldsObject;
}

export const getErrorMessage = (error: FetchBaseQueryError | SerializedError | undefined) => {
  if (!error) return;

  if (!('status' in error)) { // then it's SerializedError
    return error.message as string;
  }

  // then it's FetchBaseQueryError
  if (typeof error.status !== 'number') {
    return `${error.status}: ${error.error}`;
  }

  if (error.data && typeof error.data === 'object') {
    return 'errorFields' in error.data
      ? getErrorFieldsObject((<ICustomErrorWithFields>error).data.errorFields)
      : (<ICustomErrorWithMessage>error).data.message 
  } else {
    return error.status === 500 ? 'Internal server error' : 'An error occured';
  }
};
