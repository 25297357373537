import { forwardRef, ReactElement } from "react";
import { Icon, IconBase, IconWeight } from "@phosphor-icons/react";

const weights = new Map<IconWeight, ReactElement>([
  [
    "regular",
    <svg width="256" height="256" viewBox="0 0 256 256">
    <path
      d="M128 0C128 0 135.062 65.9192 162.572 93.4284C190.081 120.938 256 128 256 128C256 128 190.081 135.062 162.572 162.572C135.062 190.081 128 256 128 256C128 256 120.938 190.081 93.4284 162.572C65.9192 135.062 0 128 0 128C0 128 65.9192 120.938 93.4284 93.4284C120.938 65.9192 128 0 128 0Z"
      fill="inherit"
    />
    </svg>

  ],
]);

const CustomStar: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
));

CustomStar.displayName = "CustomStar";

export default CustomStar;
