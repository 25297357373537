import Cookies from 'js-cookie'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import GlobalStyles, { colors } from './GlobalStyles';
import { lazy, Suspense} from 'react';
import NotFound from './pages/NotFound';
import Loader from './components/Loader';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from './redux/userSlice';
import { useGetMeQuery } from './redux/services/fablebox/users/userApi';
import AlertMessage from './components/AlertMessage';
import { getErrorMessage } from './utils/getErrorMessage';
import { userRoles } from './types/enum.constants';
import Header from './components/Header';
import ErrorBoundary from './components/ErrorBoundary';


const SignUpPage = lazy(()=> import('./pages/auth/SignUp'));
const SignInPage = lazy(()=> import('./pages/auth/SignIn'));
const ForgotPasswordPage = lazy(()=> import('./pages/auth/ForgotPassword'));
const ResetPasswordPage = lazy(()=> import('./pages/auth/ResetPassword'));
const LandingPage = lazy(()=> import('./pages/Landing'));
const CreatePage = lazy(()=> import('./pages/Create'));
const LibraryPage = lazy(()=> import('./pages/Library'));
const ActivationPage = lazy(()=> import('./pages/auth/Activation'));
const BooksReviewPage = lazy(()=> import('./pages/BooksReview'));
const BookReviewPage = lazy(()=> import('./pages/BookReview'));
const BookOrderPage = lazy(()=> import('./pages/BookOrder'));
const ShippingPage = lazy(()=> import('./pages/Shipping'));
const OrdersPage = lazy(()=> import('./pages/Orders'));
const PaymentResultSubpage = lazy(()=> import('./pages/subpages/PaymentResult'))
const ContactUs = lazy(()=> import('./pages/ContactUs'));
const PlayGround = lazy(()=> import('./pages/PlayGround'));
const AccountSettingsPage = lazy(()=> import('./pages/Settings'));
const ReferralsPage = lazy(()=> import('./pages/Referrals'));
const PrivacyPolicy = lazy(()=> import('./pages/policies/PrivacyPolicy'));
const Terms = lazy(()=> import('./pages/policies/Terms'));
const Dashboard = lazy(()=> import('./pages/Dashboard'));

function App() {

  const {pathname} = useLocation()
  const accessToken = Cookies.get('accessToken');

  const user = useSelector(selectCurrentUser);
  
  const {isLoading, error} = useGetMeQuery(undefined, {skip: !accessToken || accessToken === 'undefined' || !!user });
  
  return (
    <ThemeProvider theme={colors}>
      <GlobalStyles />
        <AlertMessage
          enabled={Boolean(error)}
          message={{
            state: "error",
            text: getErrorMessage(error) as string
          }}
          />
      <div className={'layout ' + (user && !(pathname.includes("landing") || pathname.includes("subpages"))? 'divided': '')}>    
        {pathname.includes("auth") || pathname.includes("subpages") ? null : <Header />}
        <ErrorBoundary>
          {isLoading ? <Loader text="loading.." isBlock/>
              : <Suspense fallback={<Loader text="loading..." isBlock/>}>
              <Routes>
                
                <Route path="/" element={user? <Dashboard /> : <LandingPage />}/> 
                <Route path="/landing" element={<LandingPage />} /> 
                
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<Terms />} />
                
                <Route path="/dashboard" element={user? <Dashboard /> : <Navigate to="/auth/sign-in" replace/>} />
                <Route path="/create" element={user? <CreatePage /> : <Navigate to="/auth/sign-up" replace/>} />
                
                <Route path="/book-order" element={user? <BookOrderPage /> : <Navigate to="/"/>} />
                <Route path="/book-order/shipping" element={user? <ShippingPage /> : <Navigate to="/"/>} />

                <Route path="/library" element={user? <LibraryPage /> : <Navigate to="/"/>} />
                <Route path="/orders" element={user? <OrdersPage /> : <Navigate to="/"/>} />

                <Route path="/books-review" element={!user || user.role !== userRoles.ADMIN? <Navigate to= "/auth/sign-in"/> : <BooksReviewPage />} />
                <Route path="/books-review/:bookId" element={!user || user.role !== userRoles.ADMIN? <Navigate to= "/auth/sign-in"/> : <BookReviewPage />} />

                <Route path="/auth/sign-up" element={user ? <Navigate to="/" /> : <SignUpPage />} />
                <Route path="/auth/activation" element={user ? <Navigate to="/" /> : <ActivationPage />} />
                <Route path="/auth/sign-in" element={user ? <Navigate to="/" /> : <SignInPage />} />
                <Route path="/auth/activation" element={user ? <Navigate to="/" /> : <SignUpPage />} />
                <Route path="/auth/forgot-password" element={user ? <Navigate to="/" /> : <ForgotPasswordPage />} />
                <Route path="/auth/reset-password" element={<ResetPasswordPage />} />

                <Route path="/subpages/payment-result" element={<PaymentResultSubpage />} />

                <Route path="/settings" element={user ? <AccountSettingsPage /> : <Navigate to="/" />} />
                <Route path="/referrals" element={<ReferralsPage />} />

                <Route path="/playground" element={<PlayGround />} />


                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Suspense>
          }
        </ErrorBoundary>
      </div>
    </ThemeProvider>
  )
}

export default App
