import { Styled404Message, Styled404Section,Styled404Title } from "./styled";
import MetaHelmet from "../../components/MetaHelmet";
import { page404 } from "../../assets/images";
import Button from "../../components/Button";
import { WarningDiamond } from "../BookReview/../../assets/icons";
import { Link } from "react-router-dom";

const NotFound =()=> {
  return (
    <Styled404Section>
      <MetaHelmet
        title="Feedback Hub 404"
        description="The page you tried to access is not found or maybe is deleted"
        noindex={true}
      />
      
      <Styled404Title>404!</Styled404Title>
      <Styled404Message>Something Went Wrong</Styled404Message>
      <Button
        style={{border: '1px solid rgba(112, 45, 255, 0.18)', color: "#202020", borderRadius: '12px', fontWeight: 400}}
        color="rgba(153, 111, 244, 0.18)"
        as={Link}
        to="/contact"
      >
        <WarningDiamond color="#202020" weight="light" size={24} />Report Issue
      </Button>
      <img src={page404} alt="404 not found" />
      
    </Styled404Section>
  );
}

export default NotFound;