import { setUser } from "../../../userSlice";
import { fableboxApi } from "../api";
import Cookies from 'js-cookie'

export const userApi = fableboxApi.injectEndpoints({
  endpoints: (builder)=> ({
    getMe: builder.query<IUser, void>({
      query: () => 'users/me',
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          Cookies.remove('accessToken');
          Cookies.remove('tokenExpiration');
        }
      },
    }),
    getMyStats: builder.query<{booksCount: number, charactersCount: number, ordersCount: number,}, void>({
      query: () => 'users/get-my-stats',
    }),
    updateUserAccount: builder.mutation<{message: string, user?: IUser}, {id: string, data: FormData}>({
      query: ({id, data}) => ({
        url: `users/update-account/${id}`,
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.user){
            dispatch(setUser(data.user));
          }
        } catch {
          console.log("couldn't update account")
        }
      },
    })
  }),
  overrideExisting: false
})


export const {
  useGetMeQuery,
  useGetMyStatsQuery,
  useUpdateUserAccountMutation
} = userApi;