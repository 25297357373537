import styled from "styled-components";
import { setInterTypo, setSegoeTypo } from "../../utils/setTypo";


export const Styled404Section = styled.section`
  max-width: 125rem;
  margin: 0rem auto;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;

  & img {
    width: 100%;
    height: auto;
  }
`;

export const Styled404Message = styled.h2`
  padding: 0 1rem;
  text-align: center;
  ${setInterTypo({size: 2})}
  color: black;
`;
  
  export const Styled404Title = styled.h1`
  ${setSegoeTypo({size: 10.9})}
  color: black;
  text-align: center;
`;