
export enum bookStatus {
  IN_PROGRESS = "in-progress",
  UNDER_REVIEW = "under-review",
  APPROVED = "approved",
  FAILED = "failed"
}

export enum userRoles {
  USER = "USER",
  ADMIN = "ADMIN"
}

export enum charCreationProgressStatus {
  ANAlYZING = "analyzing",
  OPTIMIZING = "optimizing",
  QUEUED = "queued",
  IN_PROGRESS = "in_progress",
  UPLOADING = "uploading",
  COMPLETED = "completed"
}

export enum shippingLevel  {
  SAVER = "cp_saver",
  FAST = "cp_fast",
  GROUND = "cp_ground",
  POSTAL = "cp_postal",
  LIMITED = "cp_limited"
}


export enum contactMessageType {
  BUG_REPORT = "bugReport",
  SHIPPING_ISSUES = "shippingIssues",
  FEEDBACK = "feedback",
  SUGGESTIONS = "suggestions",
  OTHER = "other",
}


export enum shipmentStatus {
  BAKCLOG = "backlog", // not sent to cp yet
  FB_SENT= "fbSent", // sent from us to cloudprinter
  ORDER_VALIDATED= "CloudprinterOrderValidated",
  ITEM_VALIDATED= "ItemValidated",
  ITEM_PRODUCING= "ItemProduce",
  ITEM_PRODUCED= "ItemProduced",
  ITEM_PACKED= "ItemPacked",
  ITEM_SHIPPED= "ItemShipped",
  ITEM_ERROR= "ItemError",
  ITEM_CANCELLED = "ItemCanceled"
}