import * as Sentry from "@sentry/react";
import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from "@kazion/react-facebook-login";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App.tsx'

import { store } from './redux/store.ts';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: "https://c296c32e2f57ff57dd338d80c1353a03@o4508250390069248.ingest.de.sentry.io/4508256004800592",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/fablebox-dev-omoto.koyeb.app/, /^https:\/\/fablebox.net/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="82748060943-n189nhmu31g6vjqedvg1u5debu0di5hv.apps.googleusercontent.com">
        <FacebookProvider appId="1726500308111346" version="v20.0">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </FacebookProvider>
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
)
