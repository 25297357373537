import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';



const initialState = {
  user: null,
} as {user: IUser | null};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    logoutUser: () => initialState,
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;

// selectors
export const selectCurrentUser = (state: RootState) => state.user.user;