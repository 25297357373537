import styled, { css } from "styled-components";
import { colors } from "../../GlobalStyles";
import { mediaQuery } from "../../utils/mediaQuery";


const { lightGray, black, whitishRed, orangeRed, lightGreen, white } = colors;

export const alertColors = {
  neutral: {
    background: lightGray,
    color: black,
  },
  error: {
    background: whitishRed,
    color: orangeRed,
  },
  success: {
    background: lightGreen,
    color: white,
  }
}

interface StyledAlertProps {
  $state: string;
  $pop?: boolean;
}

export const StyledMessageWrapper = styled.div<StyledAlertProps>`
  position: fixed;
  top: ${({$pop})=> $pop? "5%": "-30%"};
  right: 10%;
  z-index: 10;
  width: 80%;
  margin: 0 auto;
  padding: 1.2rem;
  border-radius: 5px;
  ${({$state})=> {
    const {background, color} = alertColors[$state as keyof (typeof alertColors)];
    return css`
      background-color: ${background};
      border: 1px solid ${color};
    `;
    
  }}
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.4s ease-in;
  ${mediaQuery("md")}{
    top: 7%;
    right: ${({$pop})=> $pop? "3%": "-100%"};
    max-width: 45%;
  }
  ${mediaQuery("lg")}{
    max-width: 35%;
  }
`;

export const StyledAlertMessage = styled.p<StyledAlertProps>`
  color:  ${({$state})=> alertColors[$state as keyof (typeof alertColors)].color};
  font-weight: bold;
  text-transform: capitalize;
`;